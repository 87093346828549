<template>
  <div class="about">
    <PageBanner
      :firstword="'Together everyone'"
      :keyword="'achives'"
      :secondword="'more.'"
      :img="'banner-bugbountymain.jpg'"
      :subtext="'El equipo mas preparado para ayudarte a proteger tus sistemas y su informacion.'"
    />
    <div class="details">
      <div class="cards" v-for="(card, $c) in cards" :key="$c">
        <h3>{{ card.title }}</h3>
        <p>{{ card.text }}</p>
        <img :src="`${publicPath}${card.image}.svg`" alt="" />
      </div>
    </div>
    <div class="team">
      <h1>Equipo</h1>
      <p>
        Con el gran equipo de JAMA Security la informacion de tu empresa y
        los sistemas informaticos estaran mas seguros.
      </p>
      <swiper
        :slides-per-view="1"
        :space-between="40"
        :navigation="{ nextEl: '.next-slide', prevEl: '.prev-slide' }"
      >
        <swiper-slide v-for="(card, $c) in team" :key="$c">
          <div class="team-card">
            <div class="team-photo"></div>
            <h4>{{ card.name }}</h4>
            <span>{{ card.description }}</span>
          </div>
        </swiper-slide>
        <div class="navigation">
          <button class="prev-slide"><i class="flaticon-up-arrow"></i></button>
          <button class="next-slide"><i class="flaticon-up-arrow"></i></button>
        </div>
      </swiper>
    </div>
    <div class="contac-us-footer">
      <ContactUsFooter
        :content="{
          text: 'Te gustaria trabajar con nosotros, contáctanos',
          image: 'CTA-JAMA-2',
        }"
      />
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/page-banners.vue";
import ContactUsFooter from "../components/contactus-footer.vue";
import SwiperCore, { Navigation } from "swiper";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
SwiperCore.use([Navigation]);
export default {
  name: "About",
  data() {
    return {
      swiperOption: {
        centeredSlides: true,
      },
      publicPath: process.env.BASE_URL,
      cards: [
        {
          title: "Sistemas financieros",
          text:
            "Nuestros especialistas podran analizar hasta el ultimo detalle para encontrar vulnerabilidades en sistemas bancarios o financieros.",
          image: "card",
        },
        {
          title: "Redes",
          text:
            "Con los conocimientos mas aplios en Redes podremos encontrar y prevenir vulnerabilidades en tu red.",
          image: "wifi",
        },
        {
          title: "Escaneo",
          text:
            "Contamos con la gente mas actualziada en el area de Cyberseguridad.",
          image: "bug",
        },
        {
          title: "Sistemas Seguros",
          text:
            "Con nuestro equipo tus sistemas estaran mas seguros que nunca.",
          image: "monitor",
        },
      ],
      team: [
        {
          photo: "",
          name: "MSI. Alonso Jauregui Martinez",
          description:
            "Maestro en Seguridad Informatica, con experiencia de mas de 5 anos ayudara a encontrar todas las vulnerabilidades posibles en tus sistemas de informacion",
        },
        {
          photo: "",
          name: "Pedro Hernandez Aguilar",
          description:
            "Disenador encargado de la imagen de JAMA Cybersecurity.",
        },
        {
          photo: "",
          name: "Ing. Fernando Contreras",
          description:
            "Ingeniero en Sistemas especializado en desarrollo de Backend.",
        },
        {
          photo: "",
          name: "Eduardo Caffarati Correa",
          description:
            "Mercadologo especializado en marketing digital del equipo de JAMA Cybersecurity",
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    ContactUsFooter,
    PageBanner,
  },
};
</script>

<style lang="scss">
.about {
  height: 100%;
  display: flex;
  flex-direction: column;
  .banner {
    height: 690px;
    background-image: url("../assets/img/background-about.jpg");
    .text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      h1,
      h4,
      span {
        width: 1090px;
      }
      h1 {
        font-size: 80px;
        margin: 0;
      }
      h4 {
        font-size: 30px;
        margin: 20px 0;
        font-weight: 400;
      }
      span {
        font-size: 20px;
        font-weight: 100;
      }
      color: white;
      width: 1280px;
      margin: auto;
    }
  }
  .details {
    height: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 500px 500px;
    grid-row: auto auto;
    grid-column-gap: 200px;
    grid-row-gap: 120px;
    padding: 200px 250px 125px 250px;
    @media (max-width: 1280px) {
      padding: 60px;
      display: flex;
      flex-direction: column;
    }
    .cards {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      img {
        width: 150px;
      }
    }
  }
  .team {
    text-align: center;
    height: 100px 0;
    background-color: #f9f9ff;
    padding: 100px;
    h1 {
      font-size: 45px;
      margin: 0;
    }
    p {
      margin: 20px auto;
      font-size: 20px;
      font-weight: 100;
      width: 100%;
      margin: auto;
    }
    .team-card {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin: 50px 0 25px 0;
      h4 {
        color: rgba(0, 0, 51, 1);
        font-size: 20px;
        font-weight: 100;
      }
      span {
        width: 400px;
        color: rgba(103, 103, 119, 1);
        font-size: 15px;
        @media (max-width: 1280px) {
          width: 100%;
        }
      }
      .team-photo {
        width: 190px;
        height: 190px;
        background-color: #9c9ca8;
        border-radius: 100%;
      }
    }
    button {
      width: 125px;
      height: 50px;
      border-radius: 25px;
      margin: 0 15px;
      background-color: #222ac3;
      color: white;
      position: relative;
      border: none;
      i {
        right: 50px;
        position: absolute;
        top: 13px;
        &::before {
          margin: 0;
          font-size: 24px;
        }
      }
      &.prev-slide {
        i {
          transform: rotate(270deg);
        }
      }
      &.next-slide {
        i {
          transform: rotate(450deg);
        }
      }
      &.swiper-button-disabled {
        background-color: #a6a7c1;
        border: 1px solid #a6a7c1;
      }
    }
  }
}
</style>
